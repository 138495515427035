import { Children, isValidElement, useEffect, useState } from 'react';

import cn from 'classnames';

import Chevron from '@/components/Icon/Chevron';
import { Skeleton } from '@/components/Skeleton';
import { SKELETON_COLOR_SCHEMES } from '@/components/Skeleton/constants';
import Status from '@/ducks/a11y/components/Status';
import useKeyboardFocus from '@/hooks/useKeyboardFocus';

import FormControl from '../FormControl';

type Props = {
  error?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  isLabelSROnly?: boolean;
  isLoading?: boolean;
  label?: React.ReactNode;
  labelClassname?: string;
  shouldActiveStateIgnoreChildren?: boolean;
  subText?: React.ReactNode;
} & React.ComponentProps<'input'>;

const supportedChildren = ['option'];

// This could be container, select or input depending on the children
const TextField = (props: Props) => {
  const {
    children,
    className,
    defaultValue,
    disabled,
    error,
    id,
    inputRef,
    isLabelSROnly,
    isLoading,
    label,
    labelClassname,
    required,
    shouldActiveStateIgnoreChildren,
    subText,
    value,
    ...rest
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [focused, setFocussed] = useState(false);
  const [hasContent, setHasContent] = useState(!!(value || defaultValue));
  const isByKeyboard = useKeyboardFocus();

  useEffect(() => {
    if (value !== undefined && !!value !== hasContent) {
      setHasContent(!!value);
    }
  }, [value, hasContent]);

  const isNotSelect = Children.count(children) > 1 && !supportedChildren.includes(children?.[0]?.type);
  const isContainer = isValidElement(children) || isNotSelect;

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setExpanded(false);
    setFocussed(false);
    setHasContent(!!event.target.value);
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const isSelect = !!Children.count(props.children);
    setExpanded(isSelect && !expanded);
    setHasContent(!!event.currentTarget.value);
    if (props.onClick) {
      props.onClick(event);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocussed(true);
    setHasContent(!!event.target.value);
    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const subtextID = `TextFieldSubtext_${id}`;

  // If disabled option chosen, then Select is empty
  const selectedDisabledOption = Boolean(
    Array.isArray(children) && children.find((option) => option?.props?.value === value)?.props?.disabled === true,
  );

  const classes = cn('TextField', className, {
    active: hasContent || (!shouldActiveStateIgnoreChildren && !!children),
    disabled,
    empty: selectedDisabledOption,
    error: !!error,
    expanded,
    focus: focused,
    isByKeyboard: focused && isByKeyboard,
    loading: isLoading,
    'no-label': !label || isLabelSROnly,
  });

  const input = !isContainer ? (
    <div className="TextField__input">
      <FormControl
        {...rest}
        aria-describedby={subText ? subtextID : undefined}
        aria-invalid={!!error}
        component={children ? 'select' : 'input'}
        defaultValue={defaultValue}
        disabled={disabled}
        id={id}
        native
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
        ref={inputRef}
        required={required}
        value={value}
      >
        {children}
      </FormControl>

      {children && (
        <div className="TextField__caret">
          <Chevron />
        </div>
      )}

      {isLoading && (
        <div className="TextField__loadingSkeletonWrapper">
          <Skeleton {...SKELETON_COLOR_SCHEMES['gray-216-gray-100']} />
        </div>
      )}
    </div>
  ) : (
    <div className="TextField__container">{children}</div>
  );

  return (
    <div className={classes}>
      {label ? (
        <label className={cn(labelClassname, isLabelSROnly && 'sr-only')} htmlFor={id}>
          {label}
          {required && <span className="TextField__required">*</span>}
        </label>
      ) : (
        <label className="sr-only" htmlFor={id}>
          No Label
        </label>
      )}
      {input}
      {subText && (
        <span className="TextField__subText" id={subtextID}>
          {subText}
          <Status hideAfter={1000} isShown={error} srOnly>
            {subText}
          </Status>
        </span>
      )}
    </div>
  );
};

export default TextField;
