import { forwardRef, type HTMLAttributes, useEffect, useRef } from 'react';

import cn from 'classnames';

import styles from './Status.module.scss';

type StatusComponentProps = {
  children: React.ReactNode;
  focusBackAfter?: number;
  hideAfter?: number;
  isShown?: boolean;
  onHide?: () => void;
  srOnly?: boolean;
  srOnlyText?: string;
} & HTMLAttributes<HTMLDivElement>;

const Status = forwardRef<HTMLDivElement, StatusComponentProps>(
  ({ className, focusBackAfter, hideAfter, isShown, onHide, srOnly, srOnlyText, ...props }, ref) => {
    const prevFocusedElementRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
      if (isShown && ref?.current) {
        prevFocusedElementRef.current = document.activeElement as HTMLElement;

        const srOnlyElement = ref.current.querySelector('.sr-only') as HTMLElement;
        srOnlyElement?.focus();

        if (focusBackAfter && focusBackAfter > 0) {
          const timer = setTimeout(() => {
            if (document.activeElement === srOnlyElement) {
              prevFocusedElementRef.current?.focus();
            }
          }, focusBackAfter);

          return () => clearTimeout(timer);
        }
      }

      if (isShown && hideAfter && hideAfter > 0 && onHide) {
        const timer = setTimeout(onHide, hideAfter);
        return () => clearTimeout(timer);
      }
    }, [hideAfter, isShown, onHide, ref]);

    if (srOnlyText) {
      return (
        <div className={cn(!isShown && styles.hidden)} ref={ref}>
          <div aria-hidden="true" className={className} {...props} />
          <span aria-live="polite" className="sr-only" role="status" tabIndex={0}>
            {srOnlyText}
          </span>
        </div>
      );
    }

    if (!isShown) {
      return null;
    }

    return (
      <div aria-live="assertive" className={cn(srOnly && 'sr-only', className)} ref={ref} role="alert" {...props} />
    );
  },
);

Status.displayName = 'Status';

export default Status;
