import { useCallback } from 'react';

import cn from 'classnames';

import useKeyboardFocus from '@/hooks/useKeyboardFocus';

import FormControl from '../FormControl';

type FormCheckProps = {
  align?: 'top' | 'center' | 'bottom' | 'underneath';
  checked?: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  hiddenInput?: boolean;
  icon?: React.ReactNode;
  id: string;
  indeterminate?: boolean;
  inline?: boolean;
  inputTabIndex?: number;
  invalid?: boolean;
  label?: string;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  optionOrder?: number;
  role?: 'checkbox' | 'radio';
  tabIndex?: number;
  value?: string | number;
};

const FormCheck = ({
  align = 'top',
  checked,
  children,
  className,
  disabled,
  hiddenInput,
  icon,
  id,
  indeterminate,
  inline,
  inputTabIndex,
  invalid,
  label,
  labelProps = {},
  onKeyDown,
  optionOrder = 0,
  role = 'checkbox',
  tabIndex,
  ...props
}: FormCheckProps) => {
  const isByKeyboard = useKeyboardFocus();

  const classes = cn('form-check', className, {
    [align]: align !== 'top',
    disabled,
    'form-check-inline': inline,
    'hidden-input': hiddenInput,
    invalid,
  });

  const inputClasses = cn('form-check-input', {
    checked,
    indeterminate: indeterminate && !checked,
    invalid,
    keyboardFocus: isByKeyboard,
  });

  let isChecked = checked;
  let wrapperTabIndex: number | undefined = tabIndex ?? 0;
  let controlTabIndex: number | undefined = inputTabIndex ?? -1;
  const isAnySelected = props?.value || checked;

  // Enables native selection behavior for radio groups
  const clickOnFirstFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget?.click();
  }, []);

  const isRadio = role === 'radio';
  if (isRadio) {
    wrapperTabIndex = undefined;
    if (checked || (!isAnySelected && optionOrder === 0)) {
      controlTabIndex = undefined;
    } else {
      controlTabIndex = -1;
    }
    isChecked = undefined;
  }

  return (
    <div aria-label={label} className={classes} role={role} tabIndex={wrapperTabIndex}>
      <FormControl
        checked={isChecked}
        className={inputClasses}
        id={id}
        native
        onFocus={(isRadio && !isAnySelected && clickOnFirstFocus) || undefined}
        onKeyDown={onKeyDown}
        tabIndex={controlTabIndex}
        {...props}
        disabled={disabled}
      />
      <label {...labelProps} htmlFor={id}>
        {children}
      </label>
      {icon}
    </div>
  );
};

export default FormCheck;
