import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { addMonths, getDate, getDaysInMonth, getMonth, getYear, startOfYear, subYears } from 'date-fns';
import isEqual from 'lodash/isEqual';

import UIResource from '@/components/UIResource';
import { useUIResource } from '@/ducks/common/resources';
import { selectLookup } from '@/ducks/common/selectors';
import { getMinAgeDate, getMinSignupDate } from '@/helpers/data/mappers/SailorDetails';
import { currentNYTime, format, parse } from '@/helpers/util/dateUtil';
import { useAppSelector } from '@/store';

import TextField from '../TextField';

import './DateOfBirth.scss';

const hasValue = (selection: number | string) =>
  Boolean(selection) &&
  (['dd', 'mmm', 'yyyy'] as const).every((template) => !selection.toString().toLowerCase().includes(template));

const formatNumber = (number: number) => (number < 10 ? `0${number}` : number);

const yearOrDefault = (year: number) => (hasValue(year) ? year : 1970);

const isFullDate = (values: DateObject) => !(['day', 'month', 'year'] as const).some((name) => values[name] === 0);

const isValidDate = (values: DateObject) => {
  const { day, month, year } = values;
  const date = new Date(year, month - 1, day);
  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};

const stringifyDate = (values: DateObject) => {
  const { day, month, year } = values;
  return [year, month, day].map(formatNumber).join('-');
};

const getDayOptions = ({ month, year }: DateObject) => {
  const days = [
    <option disabled key="dayUnselected" value="DD">
      <UIResource id="DateOfBirth.dayLabel" />
    </option>,
  ];
  if (hasValue(month)) {
    const daysInMonth = getDaysInMonth(new Date(yearOrDefault(year), month - 1));
    for (let x = 1; x <= daysInMonth; x += 1) {
      days.push(
        <option key={`day-${x}`} value={x}>
          {x && `0${x}`.slice(-2)}
        </option>,
      );
    }
  } else {
    for (let x = 1; x <= 31; x += 1) {
      days.push(
        <option key={`day-${x}`} value={x}>
          {x && `0${x}`.slice(-2)}
        </option>,
      );
    }
  }
  return days;
};

const getMonthOptions = (currentDate: Date) => {
  let year = startOfYear(currentDate);
  const months = [
    <option disabled key="monthUnselected" value="MMM">
      <UIResource id="DateOfBirth.monthLabel" />
    </option>,
  ];
  for (let x = 1; x <= 12; x += 1) {
    months.push(
      <option key={`month-${x}`} value={x}>
        {format(year, 'MMM').toUpperCase()}
      </option>,
    );
    year = addMonths(year, 1);
  }

  return months;
};

const getYearOptions = (currentDate: Date, isSignUp = false) => {
  let year = isSignUp ? startOfYear(getMinSignupDate(currentDate)) : startOfYear(getMinAgeDate(currentDate));
  const years = [
    <option disabled key="yearUnselected" value="YYYY">
      <UIResource id="DateOfBirth.yearLabel" />
    </option>,
  ];
  for (let x = 1; x <= 100; x += 1) {
    years.push(
      <option key={`year-${x}`} value={getYear(new Date(year))}>
        {format(year, 'yyyy')}
      </option>,
    );
    year = subYears(year, 1);
  }
  return years;
};

interface Props {
  className?: string;
  disabled?: boolean;
  error?: string;
  id?: string;
  isLoading?: boolean;
  isSignUp?: boolean;
  label?: string;
  name?: string;
  onBlur?: (event: { target: { name?: string; value?: string } }) => void;
  onChange?: (event: { target: { name?: string; value?: string } }) => void;
  required?: boolean;
  subText?: string;
  tabIndex?: number;
  value?: string;
}

type DateObject = Record<'day' | 'month' | 'year', number>;

const DateOfBirth = ({ isLoading, isSignUp, onBlur, onChange, value, ...restProps }: Props) => {
  const currentDate = useAppSelector((state) => currentNYTime(selectLookup(state).serverISOtime));

  const [state, setState] = useState<DateObject>({ day: 0, month: 0, year: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value && hasValue(value)) {
      const date = parse(value, true);
      if (date) {
        const newState = {
          day: getDate(date),
          month: getMonth(date) + 1,
          year: getYear(date),
        };

        setState((prevState) => (isEqual(newState, prevState) ? prevState : newState));
      }
    }
  }, [value]);

  const handleBlur = () => {
    setTimeout(() => {
      const isFocusOutside = containerRef.current && !containerRef.current.contains(document.activeElement);
      if (isFocusOutside) {
        onBlur?.({ target: { value } });
      }
    }, 100);
  };

  const handleChange = (type: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const newState = {
      ...state,
      [type]: parseInt(value, 10),
    };
    if (!isFullDate(newState) || isValidDate(newState)) {
      setState(newState);
      onChange?.({
        target: { name: restProps.name, value: stringifyDate(newState) },
      });
    }
  };

  const { className, disabled, error, id, label, name, required, subText, tabIndex } = restProps;
  const classes = cn(className, 'DateOfBirth');

  return (
    <div className={classes} id={id} ref={containerRef}>
      <input tabIndex={-1} type="hidden" value={stringifyDate(state)} />
      <TextField className="DateOfBirth__container" error={error} label={label} required={required} subText={subText}>
        <TextField
          aria-label={useUIResource('DateOfBirth.month')}
          disabled={disabled}
          isLoading={isLoading}
          key="DateOfBirth.month"
          name={name}
          onBlur={handleBlur}
          onChange={handleChange('month')}
          required={required}
          tabIndex={tabIndex}
          value={state.month || 'MMM'}
        >
          {getMonthOptions(currentDate)}
        </TextField>
        <TextField
          aria-label={useUIResource('DateOfBirth.day')}
          disabled={disabled}
          isLoading={isLoading}
          key="DateOfBirth.day"
          name={name}
          onBlur={handleBlur}
          onChange={handleChange('day')}
          required={required}
          tabIndex={tabIndex}
          value={state.day || 'DD'}
        >
          {getDayOptions(state)}
        </TextField>
        <TextField
          aria-label={useUIResource('DateOfBirth.year')}
          disabled={disabled}
          isLoading={isLoading}
          key="DateOfBirth.year"
          name={name}
          onBlur={handleBlur}
          onChange={handleChange('year')}
          required={required}
          tabIndex={tabIndex}
          value={state.year || 'YYYY'}
        >
          {getYearOptions(currentDate, isSignUp)}
        </TextField>
      </TextField>
    </div>
  );
};

export default DateOfBirth;
