import cn from 'classnames';

import { Skeleton } from '@/components/Skeleton';
import formatMoney, { Fraction, type TFormatMoneyOptions } from '@/helpers/util/formatMoney';
import isSafari from '@/helpers/util/isSafari';
import { isDiscountAmount } from '@/helpers/util/misc';
import { type TMoneyAmount } from '@/types/common';

import './Price.scss';

export { Fraction };

type AmountProps = TFormatMoneyOptions & TMoneyAmount;

export const Amount = ({ amount, currencyCode, fraction, onlySymbol, roundUp }: AmountProps) =>
  formatMoney(amount, currencyCode || 'USD', { fraction, onlySymbol, roundUp });

const ChooseCabinPrice = ({ amount, currencyCode, roundUp }: AmountProps) => {
  const originalAmountComp = <Amount amount={amount} currencyCode={currencyCode} roundUp={roundUp} />;
  return (
    <span className="price">
      <span className="amount">{originalAmountComp}</span>
    </span>
  );
};

type ChooseCabinDiscountProps = AmountProps & {
  originalAmount: number;
};

const ChooseCabinDiscount = ({ amount, currencyCode, originalAmount, roundUp }: ChooseCabinDiscountProps) => {
  const isDiscountPresent = isDiscountAmount(originalAmount, amount);
  const originalAmountComp = <Amount amount={originalAmount - amount} currencyCode={currencyCode} roundUp={roundUp} />;
  if (isDiscountPresent) {
    return (
      <span className="price">
        <span className="amount price-with-discount">{originalAmountComp}</span>
      </span>
    );
  }

  return originalAmountComp;
};

type PriceProps = Partial<AmountProps> & {
  descriptionPrefix?: string;
  idForAriaLabelSafari?: string;
  isForceLineBreak?: boolean;
  isLoading?: boolean;
  isVariantBAndAmount?: boolean;
  isVariantBAndDiscount?: boolean;
  originalAmount?: number;
};

const Price = (props: PriceProps) => {
  const {
    amount,
    currencyCode,
    descriptionPrefix,
    idForAriaLabelSafari,
    isForceLineBreak = false,
    isLoading,
    isVariantBAndAmount,
    isVariantBAndDiscount,
    onlySymbol,
    originalAmount,
    roundUp = false,
  } = props;

  if (isLoading || amount == null || isNaN(amount) || !currencyCode) {
    return <Skeleton width="100px" />;
  }

  if (isVariantBAndAmount) {
    return <ChooseCabinPrice amount={amount} currencyCode={currencyCode} roundUp={roundUp} />;
  }

  if (isVariantBAndDiscount && originalAmount) {
    return (
      <ChooseCabinDiscount
        amount={amount}
        currencyCode={currencyCode}
        originalAmount={originalAmount}
        roundUp={roundUp}
      />
    );
  }

  const isDiscountPresent = isDiscountAmount(originalAmount, amount);
  const initialAmount = (
    <Amount amount={amount} currencyCode={currencyCode} onlySymbol={onlySymbol} roundUp={roundUp} />
  );
  const initialAmountClassName = cn('amount', {
    'force-line-break': isForceLineBreak,
    'price-with-discount': isDiscountPresent,
  });

  const price = formatMoney(amount, currencyCode, { onlySymbol, roundUp });
  const originalPrice =
    isDiscountPresent && originalAmount && formatMoney(originalAmount, currencyCode, { onlySymbol, roundUp });

  const isSafariHack = Boolean(isSafari() && idForAriaLabelSafari);
  const priceContentSR = `${descriptionPrefix} Price ${price} ${originalPrice ? 'Price without discount ' + originalPrice : ''}`;
  const originalAmountComp = (
    <span aria-hidden={isSafariHack} className={initialAmountClassName}>
      <span className="sr-only">Price</span>
      {initialAmount}
      {isSafariHack && (
        <span className="sr-only" id={idForAriaLabelSafari}>
          {priceContentSR}
        </span>
      )}{' '}
    </span>
  );

  if (isDiscountPresent && originalAmount) {
    return (
      <span aria-hidden={isSafariHack} className="price price-with-discount">
        {originalAmountComp}
        <span className="original-amount">
          <span className="sr-only"> Price without discount</span>
          {formatMoney(originalAmount, currencyCode, { onlySymbol, roundUp })}
        </span>{' '}
      </span>
    );
  }

  return originalAmountComp;
};

export default Price;
