import { forwardRef } from 'react';

import cn from 'classnames';

type ElementTypeMap = {
  input: HTMLInputElement;
  select: HTMLSelectElement;
};

type Props<T extends keyof ElementTypeMap> = {
  component?: T;
  native?: boolean;
} & React.ComponentProps<T>;

const FormControl = <T extends keyof ElementTypeMap>(
  { className, component: Component = 'input' as T, native = false, ...props }: Props<T>,
  ref?: React.Ref<ElementTypeMap[T]>,
) => {
  // @ts-expect-error Too hard to type
  return <Component className={cn(className, !native && 'form-control')} ref={ref} {...props} />;
};

export default forwardRef(FormControl);
